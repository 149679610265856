var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"min-height":"750px"}},[(_vm.loaderData == true)?_c('div',{staticClass:"loaderDiv d-flex justify-content-center align-items-center"},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"label":"Loading...","variant":"primary"}})],1):_vm._e(),(_vm.loaderData == false)?_c('div',{staticClass:"row"},_vm._l((_vm.rafcList),function(item,index){return _c('div',{key:index,staticClass:"col-6 col-lg-4 col-xl-2"},[_c('div',{staticClass:"card shadow-lg"},[_c('div',{staticClass:"card-header rounded text-white font-size-16 font-weight-bolder p-0 ellipsisText",class:{
            'bg-success': item.stockType == true && item.moyTemp >= 2 && item.moyTemp <= 8,
            'bg-danger': item.stockType == true && (item.moyTemp > 8 || item.moyTemp < 2),
            'bg-secondary': item.moyTemp === '-' || item.stockType === false,
          }},[_c('img',{staticClass:"m-1",attrs:{"src":require('@/assets/images/cube.svg'),"width":"20"}}),_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4 text-center p-0 font-size-15 ellipsisText"},[_vm._v(" "+_vm._s(item.minTemp)+" °C"),_c('br'),_vm._v("Min ")]),_c('div',{staticClass:"col-4 text-center p-0 ellipsisText",class:{
                'text-success':
                  item.moyTemp >= 2 && item.moyTemp <= 8 && item.stockType === true,
                'text-danger': item.stockType === true && (item.moyTemp > 8 || item.moyTemp < 2),
                'text-secondary': item.moyTemp === '-' || item.stockType === false,
              },staticStyle:{"font-size":"19px","font-weight":"900","text-shadow":"0px 1px, 1px 0px, 1px 0px"}},[_c('b',[_vm._v(_vm._s(item.moyTemp)+" °C")])]),_c('div',{staticClass:"col-4 text-center p-0 font-size-15 ellipsisText"},[_vm._v(" "+_vm._s(item.maxTemp)+" °C"),_c('br'),_vm._v("Max ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center p-0 font-weight-lighter ellipsisText"},[_vm._v(" "+_vm._s(_vm.dateN)+" ")])])])])])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
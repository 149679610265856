<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

//import RafcData from "./rafc-data";
import RafcCards from "./rafc-cards";

import {
  layoutComputed,
  authComputed,
  layoutMethods,
  rafcMethods,
  rafcComputed,
} from "@/state/helpers";
/**
 * Rafc component
 */
export default {
  page: {
    title: "Rafc",
    meta: [{ name: "Rafc" }],
  },
  components: {
    Layout,
    PageHeader,
    RafcCards,
    //RafcData,
  },
  mounted() {
    this.changeMenuType();
  },
  created() {
    this.retrieveRafc();
    this.intervalRAFC = setInterval(() => {
      this.retrieveRafc();
    }, 1000 * 60 * 5);
  },
  data() {
    return {
      title: "Rafc",
      items: [
        {
          text: "Nextrack",
        },
        {
          text: "Rafc",
          active: true,
        },
      ],

      loaderData: true,
      intervalRAFC: null,

      rafcData: [],

      manageData: {
        room: null,
        stockType: null,
      },

      err: {
        room: null,
        stockType: null,
      },

      chambresRAFC: [
        { value: null, text: "-- Chambre --", disabled: true },
        { value: "1", text: "chambre froide 1" },
        { value: "2", text: "chambre froide 2" },
        { value: "3", text: "chambre froide 3" },
        { value: "4", text: "chambre froide 4" },
        { value: "5", text: "chambre froide 5" },
        { value: "6", text: "chambre froide 6" },
        { value: "7", text: "chambre froide 7" },
        { value: "8", text: "chambre froide 8" },
        { value: "9", text: "chambre froide 9" },
        { value: "10", text: "chambre froide 10" },
        { value: "11", text: "chambre froide 11" },
        { value: "12", text: "chambre froide 12" },
        { value: "13", text: "chambre froide 13" },
        { value: "14", text: "chambre froide 14" },
        { value: "15", text: "chambre froide 15" },
        { value: "16", text: "chambre froide 16" },
        { value: "17", text: "chambre froide 17" },
        { value: "18", text: "chambre froide 18" },
        { value: "19", text: "chambre froide 19" },
        { value: "20", text: "chambre froide 20" },
        { value: "chgd", text: "couloire droit" },
        { value: "chgg", text: "couloire gauche" },
        { value: "chpd", text: "couloire petit droit" },
        { value: "chpg", text: "couloire petit gauche" },
      ],
      stockTypes: [
        { value: null, text: "-- stockage --", disabled: true },
        { value: "VIDE", text: "VIDE" },
        { value: "24 H", text: "24 H" },
      ],

      fullscreen: false,
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...rafcComputed,
  },
  methods: {
    ...layoutMethods,
    ...rafcMethods,

    retrieveRafc() {
      this.getRafcBy({ province: "RAFC" }).then(() => {
        this.loaderData = false;
      });
    },
    changeMenuType() {
      this.changeLeftSidebarType({ leftSidebarType: "icon" });
    },
    toggle() {
      this.fullscreen = !this.fullscreen;
    },
    showModalManage() {
      this.$bvModal.show("manage-modal");
    },
    hideModalManage() {
      this.$bvModal.hide("manage-modal");
    },
    manage(){
      if (!this.manageData.room)
        this.err.room = this.$t("msgError.obligField.text");
      else this.err.room = "";
      if (!this.manageData.stockType)
        this.err.stockType = this.$t("msgError.obligField.text");
      else this.err.stockType = "";

      if(!this.err.room && !this.err.stockType){
        this.manageRAFC(this.manageData).then(() => {
          this.retrieveRafc();
          this.hideModalManage();
          this.loaderData = false;
        });
      }
      
    }
  },
  watch: {
    getRafc(newVal) {
      let results = newVal.results;
      let keys = Object.keys(results);
      let chambres = {};
      keys.map((obj) => {
        let chambre = results[obj].chambre ? results[obj].chambre : null;
        if (chambres[chambre]) {
          chambres[chambre].push(results[obj]);
        } else {
          chambres[chambre] = [results[obj]];
        }
      });
      let keys2 = Object.keys(chambres);
      keys2.map((obj) => {
        let max;
        let min;
        let totalDevices = 0;
        let totalTemp = 0;
        let vaccin = false;
        chambres[obj].map((device) => {
          if(device.stockType != "VIDE") vaccin = true;
          if (device.event) {
            max
              ? device.event.temp > max
                ? (max = device.event.temp)
                : max
              : (max = device.event.temp);
            min
              ? device.event.temp < min
                ? (min = device.event.temp)
                : min
              : (min = device.event.temp);
            totalDevices += 1;
            totalTemp += device.event.temp;
          }
        });
        chambres[obj] = {
          max: max,
          min: min,
          moy: totalTemp / totalDevices,
          stockType: vaccin
        };
      });
      this.rafcData = chambres;
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalRAFC);
    this.changeLeftSidebarType({ leftSidebarType: "light" });
  },
};
</script>

<template>
  <Layout>
    <fullscreen v-model="fullscreen" class="full-screen">
      <PageHeader :title="title" :items="items" />
      <div class="row pb-0">
        <div class="col-11">
          <b-button variant="light" @click="showModalManage">
            Stockage
          </b-button>
        </div>
        <div class="col-1 text-right">
          <button type="button" @click="toggle">
            <i v-if="!fullscreen" class="font-size-22 ri-fullscreen-line"></i
            ><i
              v-if="fullscreen"
              class="font-size-22 ri-fullscreen-exit-line"
            ></i>
          </button>
        </div>
      </div>
      <div class="row">
        <b-modal
          id="manage-modal"
          ref="manage-modal"
          size="lg"
          :title="this.$t('modals.manageRAFC.text')"
        >
          <!-- Room -->
          <div class="row">
            <div class="col-12 col-lg-12">
              <label for="room">{{ $t("modals.room.text") }}</label>
              <b-form-select
                id="room"
                v-model="manageData.room"
                :options="chambresRAFC"
                class="mb-1"
                :state="err.room ? false : null"
              ></b-form-select>
              <b-form-invalid-feedback>
                {{ err.room }}
              </b-form-invalid-feedback>
            </div>
          </div>
          <!-- Stockage -->
          <div class="row">
            <div class="col-12 col-lg-12">
              <label for="stockType">{{ $t("modals.stockType.text") }}</label>
              <b-form-select
                id="stockType"
                v-model="manageData.stockType"
                :options="stockTypes"
                class="mb-1"
                :state="err.stockType ? false : null"
              ></b-form-select>
              <b-form-invalid-feedback>
                {{ err.stockType }}
              </b-form-invalid-feedback>
            </div>
          </div>

          <template #modal-footer="{ cancel }">
            <div class="row p-2">
              <div class="col-10 text-right p-1">
                <b-button variant="success" class="text-white" @click="manage">
                  {{ $t("buttons.valid.text") }}
                </b-button>
              </div>
              <div class="col-2 text-center p-1">
                <b-button @click="cancel()">
                  {{ $t("buttons.cancel.text") }}
                </b-button>
              </div>
            </div>
          </template>
        </b-modal>
      </div>
      <div class="row">
        <div class="col-12">
          <RafcCards v-bind:rafc="rafcData" :loaderData="loaderData" />
        </div>
      </div>
    </fullscreen>
  </Layout>
</template>
<style scoped>
.full-screen {
  background-color: #f1f5f7;
  padding: 15px;
}
</style>
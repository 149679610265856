<script>
/**
 * RAFC Cards component
 */
import moment from "moment";
import { authComputed } from "@/state/helpers";
export default {
  props: ["rafc", "loaderData"],
  components: {},
  data() {
    return {
      rafcList: [],
      dateN: null,
    };
  },
  created() {
    this.chargeRafc(this.rafc);
  },
  computed: {
    ...authComputed,
    dateNow() {
      return moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
    },
  },
  mounted() {},
  methods: {
    chargeRafc(data) {
      this.rafcList = data;
    },
  },
  watch: {
    rafc(newVal) {
      //this.rafcList = newVal;
      let rafcData = {
        5: {
          name: "Chambre froide 5",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        6: {
          name: "Chambre froide 6",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        7: {
          name: "Chambre froide 7",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        8: {
          name: "Chambre froide 8",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        9: {
          name: "Chambre froide 9",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        10: {
          name: "Chambre froide 10",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        11: {
          name: "Chambre froide 11",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        12: {
          name: "Chambre froide 12",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        13: {
          name: "Chambre froide 13",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        14: {
          name: "Chambre froide 14",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        15: {
          name: "Chambre froide 15",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        16: {
          name: "Chambre froide 16",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        17: {
          name: "Chambre froide 17",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        18: {
          name: "Chambre froide 18",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        19: {
          name: "Chambre froide 19",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        20: {
          name: "Chambre froide 20",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        chgd: {
          name: "Couloire droit",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        chgg: {
          name: "Couloire gauche",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        chpd: {
          name: "Couloire petites chambres droite",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
        chpg: {
          name: "Couloire petites chambres gauche",
          moyTemp: 0,
          maxTemp: 0,
          minTemp: 0,
        },
      };
      /* newVal.map(obj=>{
        rafcData[obj._id] = obj
      }) */
      Object.keys(newVal).map((obj) => {
        if (rafcData[obj]) {
          rafcData[obj] = {
            ...rafcData[obj],
            moyTemp: newVal[obj].moy ? newVal[obj].moy.toFixed(2) : "-",
            maxTemp: newVal[obj].max ? newVal[obj].max.toFixed(2) : "-",
            minTemp: newVal[obj].min ? newVal[obj].min.toFixed(2) : "-",
            stockType: newVal[obj].stockType
          };
        }
      });
      this.rafcList = Object.values(rafcData);
      this.dateN = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
    },
  },
};
</script>

<template>
  <div style="min-height: 750px">
    <div
      v-if="loaderData == true"
      class="loaderDiv d-flex justify-content-center align-items-center"
    >
      <b-spinner
        label="Loading..."
        variant="primary"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <div v-if="loaderData == false" class="row">
      <div
        v-for="(item, index) in rafcList"
        :key="index"
        class="col-6 col-lg-4 col-xl-2"
      >
        <div class="card shadow-lg">
          <div
            class="
              card-header
              rounded
              text-white
              font-size-16 font-weight-bolder
              p-0
              ellipsisText
            "
            :class="{
              'bg-success': item.stockType == true && item.moyTemp >= 2 && item.moyTemp <= 8,
              'bg-danger': item.stockType == true && (item.moyTemp > 8 || item.moyTemp < 2),
              'bg-secondary': item.moyTemp === '-' || item.stockType === false,
            }"
          >
            <img
              class="m-1"
              :src="require('@/assets/images/cube.svg')"
              width="20"
            />
            {{ item.name }}
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-4 text-center p-0 font-size-15 ellipsisText">
                {{ item.minTemp }} °C<br />Min
              </div>
              <div
                class="col-4 text-center p-0 ellipsisText"
                :class="{
                  'text-success':
                    item.moyTemp >= 2 && item.moyTemp <= 8 && item.stockType === true,
                  'text-danger': item.stockType === true && (item.moyTemp > 8 || item.moyTemp < 2),
                  'text-secondary': item.moyTemp === '-' || item.stockType === false,
                }"
                style="
                  font-size: 19px;
                  font-weight: 900;
                  text-shadow: 0px 1px, 1px 0px, 1px 0px;
                "
              >
                <b>{{ item.moyTemp }} °C</b>
              </div>
              <div class="col-4 text-center p-0 font-size-15 ellipsisText">
                {{ item.maxTemp }} °C<br />Max
              </div>
            </div>
            <div class="row">
              <div
                class="col-12 text-center p-0 font-weight-lighter ellipsisText"
              >
                {{ dateN }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.ellipsisText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.loaderDiv {
  position: absolute;
  z-index: 5000;
  width: 100%;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
}
</style>